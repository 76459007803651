<template>
  <el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="handleClose">
    <el-carousel
      indicator-position="outside"
      :autoplay="false"
      :arrow="filteredBannerData.length > 1 ? 'always' : 'never'"
    >
      <el-carousel-item v-for="(item, i) in filteredBannerData" :key="i">
        <PopupContent
          :isPC="isPC"
          :handleClose="handleClose"
          :bannerDetails="item"
          :bannerImage="item.bannerImage"
          :clickAction="item.clickAction"
          :addRemoveSnoozeBanner="item.addRemoveSnoozeBanner"
        />
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
import PopupContent from '@/components/home/popupContent/PopupContent.vue';
import { setCookies } from '@/util/cookies';
import { apiGetBanners, apiSnoozeBanner } from '@/resource';
import { getPopupBannerDisplayPageEnumFromRoutePath } from '@/constants/carouselBanner';

export default {
  name: 'CarouselBanner',
  components: {
    PopupContent
  },
  data() {
    return {
      dialogVisible: true,
      isPC: window.innerWidth > 768,
      bannerData: [],
      bannersToSnooze: []
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    isHomePage() {
      return this.currentRoute === '/home' || this.currentRoute === '/register';
    },
    filteredBannerData() {
      return this.bannerData.filter(item => {
        return this.isPC ? item.showPcBanner === true : item.showMobileBanner === true;
      });
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    currentRoute: {
      async handler() {
        await this.initializeBannerShowData();
      }
    },
    deep: true
  },
  methods: {
    generateCookiesName() {
      const cookiesName =
        'banner' +
        this.$store.state.common.userID +
        this.bannerData.reduce((accumulator, current) => {
          return accumulator + '_' + current.bannerName;
        }, '');
      return cookiesName;
    },
    handleClose() {
      this.dialogVisible = false;
      if (this.bannersToSnooze.length > 0) apiSnoozeBanner(this.bannersToSnooze);
      if (this.isHomePage) this.$store.commit('common/setShowHomePopup', false);
      setCookies(this.generateCookiesName(), false, 10080);
    },
    handleResize() {
      this.isPC = window.innerWidth > 768;
    },
    async getAdminBanners() {
      // Get banners uploaded through admin portal's announcement setting
      const { countryCode } = this.$store.state.common;
      const displayPage = getPopupBannerDisplayPageEnumFromRoutePath(this.currentRoute);
      if (displayPage == null) {
        return;
      }
      const res = await apiGetBanners({ country: countryCode, displayPage: displayPage });

      if (res.status === 200 && res.data.success && res.data.rows.length > 0) {
        return res.data.rows
          .map(e => {
            const addRemoveSnoozeBanner = e.snoozeDuration ? action => this.addRemoveSnoozeBanner(e.id, action) : null;
            let getLangBanner = e.bannerDocuments.filter(f => f.languageCode === this.lang).filter(h => h.isDel === 0);

            if (getLangBanner.length == 0) {
              getLangBanner = e.bannerDocuments.filter(f => f.languageCode === 'en_US');
            }

            return {
              bannerName: `adminBanner-${e.id}`,
              bannerType: 'static',
              bannerImage: `api/${getLangBanner[0].webDocument}`,
              bannerMobileImage: `api/${getLangBanner[0].appDocument}`,
              showPcBanner: true,
              showMobileBanner: true,
              priority: e.priority,
              addRemoveSnoozeBanner: addRemoveSnoozeBanner,
              clickAction: getLangBanner[0].callToActionLink
                ? () => {
                    window.open('https://' + getLangBanner[0].callToActionLink, '_blank');
                  }
                : undefined
            };
          })
          .sort((a, b) => a.priority - b.priority);
      } else {
        this.dialogVisible = false;
      }

      return [];
    },
    addRemoveSnoozeBanner(id, action) {
      switch (action) {
        case 'add':
          this.bannersToSnooze.push(id);
          break;
        case 'remove':
          this.bannersToSnooze = this.bannersToSnooze.filter(e => e !== id);
          break;
      }
    },
    async initializeBannerShowData() {
      const adminBanner = await this.getAdminBanners();
      if (this.isHomePage) {
        this.bannerData = [...adminBanner];
      } else if (this.currentRoute === '/downloads' || this.currentRoute === '/depositFunds') {
        this.bannerData = adminBanner;
      }

      if (adminBanner && adminBanner.length > 0) this.dialogVisible = true;
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.initializeBannerShowData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/carouselBanner.scss';
</style>
